@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --black: #191919;
  }

  body {
    font-family: 'Inter', sans-serif;
    color: var(--black);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  menu {
    padding-inline-start: 40px;
  }

  img {
    max-width: unset;
    height: unset;
  }

  svg {
    display: inline-block;
  }

  p {
    margin: 0;
    font-weight: 400;
    line-height: 1.5;
  }
  strong {
    font-weight: 600;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Suisse Intl', sans-serif;
    margin: 0;
    font-size: initial;
    font-weight: initial;
  }

  /*
    As before migration to DS after autocomplete we used default 
    browser styles for input fields, and they worked almost fine(with some extra whitespace)
    we wanted to keep the same behavior after migration.
    But as our input has smaller size than container because of horizontal 1 rem padding
    we need to adjust width of input to be the same as container by using negative margins.
    and than add paddings to make it look like before.
  */
  input:-webkit-autofill {
    display: block;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    padding-left: 1rem;
    margin-right: -1rem;
    padding-right: 1rem;
  }

  button {
    -webkit-tap-highlight-color: transparent;
    color: var(--black);
  }

  input {
    color: var(--black);
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  ul,
  ol {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ol {
    list-style-type: decimal;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  #nprogress .bar {
    @apply bg-trustedBlue h-1;
  }

  #nprogress .peg {
    box-shadow:
      0 0 10px rgb(0 134 234 / var(--tw-bg-opacity)),
      0 0 5px rgb(0 134 234 / var(--tw-bg-opacity));
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@layer components {
  .raw-html-global {
    @apply body-16-light;
  }

  .raw-html-global *:before,
  .raw-html-global *:after {
    display: inline !important;
  }

  .raw-html-global a:not(:has(div)) {
    @apply link-16;
  }

  .raw-html-global p {
    @apply body-16-light;
    text-align: inherit !important;
    margin: 0;
  }
  .raw-html-global p strong {
    vertical-align: middle;
  }
  .raw-html-global h1,
  .raw-html-global h2,
  .raw-html-global h3,
  .raw-html-global h4,
  .raw-html-global h5,
  .raw-html-global h6,
  .raw-html-global .h1,
  .raw-html-global .h2,
  .raw-html-global .h3,
  .raw-html-global .h4,
  .raw-html-global .h5,
  .raw-html-global .h6 {
    @apply mb-4 mt-8;
  }

  .raw-html-global h1,
  .raw-html-global .h1 {
    @apply web-t1-semibold;
  }
  .raw-html-global h2,
  .raw-html-global .h2 {
    @apply web-t3-semibold;
  }
  .raw-html-global h3,
  .raw-html-global .h3 {
    @apply web-t4-semibold;
  }
  .raw-html-global h4,
  .raw-html-global .h4 {
    @apply global-t5-semibold;
  }
  .raw-html-global h5,
  .raw-html-global .h5 {
    @apply global-t6-semibold;
  }
  .raw-html-global h6,
  .raw-html-global .h6 {
    @apply global-t6-semibold;
  }

  .raw-html-global .sea-section .benefits-wrapper {
    @apply flex-col sm:flex-row;
  }

  .raw-html-global .sea-section .benefits-wrapper span {
    @apply justify-center;
  }

  .raw-html-global table {
    @apply border-pearl mt-4 w-full border-collapse border border-solid bg-white;
  }

  .raw-html-global table tbody {
    @apply table-fixed;
  }

  .raw-html-global table tr {
    @apply border-pearl h-20 w-full border-b border-solid;
  }

  .raw-html-global table td {
    @apply border-pearl border-l border-solid px-4;
  }
  .raw-html-global table td li {
    @apply !text-left;
  }
  .raw-html-global img {
    display: inline;
  }
  .raw-html-full-width .fr-video,
  .raw-html-full-width iframe,
  .raw-html-full-width video {
    @apply !w-full;
  }
  .raw-html-full-width img {
    @apply xs:min-h-[330px] min-h-[270px] !w-full sm:min-h-[69px] md:min-h-[112px];
  }
}
